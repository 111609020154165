import SEO from "../../components/seo";
import Layout from "../../components/layout";
import React from "react";
import {Col, Row, Section} from "../index";
import {graphql, useStaticQuery} from "gatsby";
import {Button, ButtonLink} from "../../components/button";
import styled from "styled-components";
const RESTAURANTS = require("../../restaurants.json");
const RestaurantWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
  a {
    text-decoration: none;
        min-width: 200px;
        text-align: center;
    }
margin-bottom: 40px;
@media (max-width: 767px){
  img {
    width: 64px;
    padding-right: 10px;
    margin: 0;
  }
  p {
  font-size: 12px;
  }
  a {
    font-size: 12px !important;
    text-decoration: none;
    min-width: 150px;
    text-align:center;
  }
}
`;
export const Restaurants = () => {
    const images = useStaticQuery(graphql`
        query {
            header: file(relativePath: { eq: "restaurants-background.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return <Layout smallHeader={true} title="Restaurants" image={images.header.childImageSharp.fluid}>
        <SEO title="Restaurants" description={"Unsere Partner auf einen Blick."} lang={"de"}/>
        <Row style={{alignItems: "flex-start", padding: "40px 20px", flexDirection: "column"}}>
            <p>
                Im Folgenden eine Liste aller bisher teilnehmenden MainOrder-Restaurants mit Anschrift und Weiterleitung zum App-Download:
            </p>
            <Col>
                {
                    Object.keys(RESTAURANTS).map(restaurantKey => {
                        const restaurant = RESTAURANTS[restaurantKey];
                        return <RestaurantWrapper key={restaurantKey}>
                            <img width="100%" style={{maxWidth: "150px"}} src={"/logos/" + restaurantKey + ".png"} />
                            <strong>{restaurant.name}</strong>
                            <ButtonLink href={"/restaurants/redirect/?restaurant=" + restaurantKey}>App Download</ButtonLink>
                        </RestaurantWrapper>
                    })
                }
            </Col>
        </Row>
    </Layout>
}

export default Restaurants
